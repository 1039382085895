<mat-toolbar class="matero-toolbar">
  <button mat-icon-button class="matero-toolbar-button" *ngIf="showToggle"
          (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <smhfs-fe-branding *ngIf="showBranding"></smhfs-fe-branding>
  <span fxFlex></span>

  <button mat-icon-button class="matero-toolbar-button">
    <mat-icon>search</mat-icon>
  </button>

  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <smhfs-fe-notification fxHide.lt-sm></smhfs-fe-notification>

  <smhfs-fe-translate></smhfs-fe-translate>

  <smhfs-fe-user></smhfs-fe-user>

  <button mat-icon-button class="matero-toolbar-button" fxHide.lt-sm
          (click)="toggleSidenavNotice.emit()">
    <mat-icon>list</mat-icon>
  </button>
</mat-toolbar>