import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { PreloaderService } from './core';

@Component({
  selector: 'smhfs-fe-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, AfterViewInit {
  message = '';

  constructor(private preloader: PreloaderService, private readonly http: HttpClient) {}

  ngOnInit() {
    this.http
      .get<{ message: string }>('/api')
      .pipe(
        take(1),
        map((response) => response.message),
      )
      .subscribe((message) => { this.message = message; });
  }

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
