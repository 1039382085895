<div
  class="matero-container-wrap"
  [ngClass]="{
    'matero-sidenav-collapsed': options.sidenavCollapsed && options.navPos !== 'top',
    'matero-navbar-side': options.navPos === 'side',
    'matero-navbar-top': options.navPos === 'top',
    'matero-header-above': options.headerPos === 'above',
    'matero-header-fixed': options.headerPos === 'fixed',
    'matero-header-white': options.theme === 'light'
  }"
  [dir]="options.dir!"
>
  <ng-progress></ng-progress>

  <!-- Header Above -->
  <smhfs-fe-header
    *ngIf="options.showHeader && options.headerPos === 'above'"
    [showBranding]="true"
    (toggleSidenav)="sidenav.toggle()"
    (toggleSidenavNotice)="sidenavNotice.toggle()"
  >
  </smhfs-fe-header>

  <mat-sidenav-container class="matero-container" autosize autoFocus>
    <mat-sidenav
      #sidenav
      class="matero-sidenav"
      [mode]="isOver ? 'over' : 'side'"
      [opened]="options.navPos === 'side' && options.sidenavOpened && !isOver"
      (openedChange)="sidenavOpenedChange($event)"
      (closedStart)="sidenavCloseStart()"
    >
      <smhfs-fe-sidebar
        [showToggle]="!isOver"
        [showUser]="!!options.showUserPanel"
        [showHeader]="options.headerPos !== 'above'"
        (toggleCollapsed)="toggleCollapsed()"
        [toggleChecked]="!!options.sidenavCollapsed"
      >
      </smhfs-fe-sidebar>
    </mat-sidenav>

    <mat-sidenav #sidenavNotice position="end" mode="over">
      <smhfs-fe-sidebar-notice></smhfs-fe-sidebar-notice>
    </mat-sidenav>

    <mat-sidenav-content #content class="matero-content-wrap">
      <smhfs-fe-header
        *ngIf="options.showHeader && options.headerPos !== 'above'"
        [showToggle]="!options.sidenavCollapsed && options.navPos !== 'top'"
        [showBranding]="options.navPos === 'top'"
        (toggleSidenav)="sidenav.toggle()"
        (toggleSidenavNotice)="sidenavNotice.toggle()"
      >
      </smhfs-fe-header>

      <smhfs-fe-topmenu *ngIf="options.navPos === 'top'"></smhfs-fe-topmenu>

      <div class="matero-content">
        <router-outlet></router-outlet>
      </div>

      <div class="build-info">
        {{ buildInfo }}
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
