<div class="matero-sidebar-header" *ngIf="showHeader">
  <smhfs-fe-branding></smhfs-fe-branding>
  <span fxFlex></span>
  <mat-slide-toggle *ngIf="showToggle" [checked]="toggleChecked" (change)="toggleCollapsed.emit()"> </mat-slide-toggle>
</div>

<div class="matero-sidebar-main scrollbar-none">
  <smhfs-fe-user-panel *ngIf="showUser"></smhfs-fe-user-panel>
  <smhfs-fe-sidemenu [ripple]="showToggle"></smhfs-fe-sidemenu>
</div>
