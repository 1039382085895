import {
  Directive, Input, SkipSelf, Optional,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[smhfsFeDisableControl]',
})
export class DisableControlDirective {
  @Input() set smhfsFeDisableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    this.ngControl.control?.[action]();
  }

  constructor(@Optional() @SkipSelf() private ngControl: NgControl) {}
}
