import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  baseUrl: '',
  useHash: false,
  loggerLevel: NgxLoggerLevel.TRACE,
  i18nDefaultLanguage: 'bg',
  i18nSupportedLanguages: ['bg', 'en'],
  i18nTranslationsLocationPrefix: 'i18n_',
  i18nTranslationsLocationSuffix: '.json',
  runTimeConfigUrl: '/assets/config/application.json',
  gatewayUrl: '/api',
  defaultLandingUri: 'dashboard',
};
