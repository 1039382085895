import { Component, ViewEncapsulation } from '@angular/core';
import { APP_VERSION } from 'app-version';

@Component({
  selector: 'smhfs-fe-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthLayoutComponent {
  buildInfo = `Build v${APP_VERSION.version}-${APP_VERSION.hash}`;
}
