import {
  Component, ChangeDetectionStrategy, ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'smhfs-fe-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// export class DashboardComponent implements OnInit {
export class DashboardComponent {
  constructor(private cdr: ChangeDetectorRef) {}

  // ngOnInit() {}
}
